import { Feature, FeatureCollection, Geometry } from '@turf/helpers'
import { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useEffect, useReducer, useState } from 'react'
import { useErrorHandler } from '../../../hooks/use-error-handler'
import { useRequest } from '../../../hooks/useRequest'
import { useMutation, useQuery } from 'react-query'
import { createToastMessage, ToastMessage } from '@msaf/core-react'
import { FeatureFilterFunction } from '../feature-filter-groups/types'
import labels from '../../../constants/labels'
import { MapUIConfig } from '@msaf/maps-common'
import { buildTitleForFeature } from '.'
import { elasticApm } from '../../../logging/elastic-apm'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'

export interface UseFeatureStateProps {
  featuresUrl: string
  featureFilters?: FeatureFilterFunction[]
  config?: MapUIConfig
}

export type BaseFeatureState = {
  featureFilters: FeatureFilterFunction[]
}

export type InitialisedFeatureStatePrefiltering = BaseFeatureState & {
  collections: {
    unfiltered: FeatureCollection<Geometry>
  }
  initialised: true
  isDirty: boolean
}

interface InitialisedFeatureState {
  collections: {
    filtered: FeatureCollection<Geometry>
    unfiltered: FeatureCollection<Geometry>
  }
  initialised: true
  isDirty: boolean
}

interface UninitialisedFeatureState {
  collections?: {
    filtered: undefined
    unfiltered: undefined
  }
  initialised: false
  isDirty: false
}

export type FeatureState = BaseFeatureState & (InitialisedFeatureState | UninitialisedFeatureState)

export type UseFeatureStateReturn = {
  state: FeatureState
  isLoading: boolean
  removeFeature: (feature: string | number) => void
  upsertFeature: (feature: Feature<Geometry>) => void
  saveFeatures: (collection: FeatureCollection<Geometry>) => void
  addFeatureFilter: (filter: FeatureFilterFunction) => void
  removeFeatureFilter: (filter: FeatureFilterFunction) => void
}

type FeatureAction =
  | { type: 'init'; collection: FeatureCollection<Geometry> }
  | { type: 'add'; feature: Feature<Geometry> }
  | { type: 'remove'; featureId: string | number }
  | { type: 'edit'; feature: Feature<Geometry> }
  | { type: 'upsert'; feature: Feature<Geometry> }
  | { type: 'add-feature-filter'; filter: FeatureFilterFunction }
  | { type: 'remove-feature-filter'; filter: FeatureFilterFunction }

// Does the filtering based on the unfiltered features and packs them back into the collection
const filterFeatures = (state: InitialisedFeatureStatePrefiltering): FeatureState => ({
  ...state,
  collections: {
    ...state.collections,
    filtered: {
      ...state.collections?.unfiltered,
      features: state.collections.unfiltered.features.filter((feature) =>
        state.featureFilters.filter((f) => f != null).every((v) => v(feature)),
      ),
    },
  },
})

const featureStateReducer = (state: FeatureState, action: FeatureAction): FeatureState => {
  switch (action.type) {
    case 'init': {
      return filterFeatures({
        ...state,
        collections: {
          unfiltered: cloneDeep(action.collection),
        },
        isDirty: false,
        initialised: true,
      })
    }

    case 'upsert': {
      if (!state.initialised) throw new Error('Cannot edit an uninitialised collection')
      const unfilteredCollection = cloneDeep(state.collections.unfiltered)
      const idx = unfilteredCollection.features.findIndex((f) => f.id === action.feature.id)

      if (idx !== -1) {
        const prevFeatureState = unfilteredCollection.features[idx]
        // Check if any properties have been updated and mark the collection state as dirty
        // If state is already marked as dirty, we need not perform the comparison again
        if (!state.isDirty) {
          state.isDirty = !isEqual(prevFeatureState.properties, action.feature.properties)
        }
        unfilteredCollection.features[idx] = action.feature
      } else {
        unfilteredCollection.features.push(action.feature)
        // Mark the collection as dirty when a new feature is added
        state.isDirty = true
      }

      return filterFeatures({ ...state, collections: { unfiltered: unfilteredCollection } })
    }

    case 'remove': {
      if (!state.initialised) throw new Error('Cannot remove from an uninitialised collection')
      const unfilteredCollection = cloneDeep(state.collections.unfiltered)
      const removeIndex = unfilteredCollection.features.findIndex((f: Feature) => f.id === action.featureId)

      if (removeIndex === -1) {
        throw new Error("Not able to remove an item that doesn't exist in the collection")
      }

      unfilteredCollection.features.splice(removeIndex, 1)

      return filterFeatures({ ...state, collections: { unfiltered: unfilteredCollection }, isDirty: true })
    }

    case 'remove-feature-filter':
    case 'add-feature-filter': {
      const featureFilters =
        action.type === 'add-feature-filter'
          ? // Add the filter to the list
            [...state.featureFilters, action.filter]
          : // If its not add then remove the filter from the list
            state.featureFilters.filter((f) => f && f !== action.filter)

      if (state.initialised) {
        return filterFeatures({
          ...state,
          featureFilters,
          collections: {
            unfiltered: state.collections.unfiltered,
          },
        })
      } else {
        return { ...state, featureFilters }
      }
    }

    default:
      throw new Error('No default action for this reducer')
  }
}

export function useFeaturesQuery(featuresUrl: string) {
  const { client } = useRequest()
  const { data, isError, error, refetch, isLoading } = useQuery<
    AxiosResponse<FeatureCollection<Geometry>>,
    AxiosError<unknown>
  >(['feature-map'], () => client.get(featuresUrl), {
    cacheTime: 200, // Features should always be up to date so the cache time is short.
  })

  useErrorHandler(isError, error)

  return {
    data,
    isError: false,
    error: null as AxiosError<unknown> | null,
    isSuccess: true,
    refetch,
    isLoading,
  }
}

export function useFeaturesUpdate(featuresUrl: string, config?: MapUIConfig) {
  const { client } = useRequest()
  const mutation = useMutation<
    AxiosResponse<FeatureCollection<Geometry>>,
    AxiosError<Record<string, Record<string, string[]> | string> | string>,
    FeatureCollection
  >((data) => client.put(featuresUrl, data), {
    onSuccess: () => {
      createToastMessage(<ToastMessage messageType='success' title='Success' message='Features saved.' />)
    },
    onError: (response, data) => {
      // Average error handling... We did nothing before so this is better but it could be problematic for
      // lots of errors / features and makes little effort to help the users.
      try {
        const errors = response.response?.data

        if (errors === undefined || errors === '') {
          throw new Error("Didn't get any error data back.")
        }

        // Run though the errors for each feature.
        Object.entries(errors ?? {}).map(([featureIndex, errors]) => {
          const featureWithError = data.features.at(parseInt(featureIndex))

          // This isn't a feature error we need to handle it differently.
          if (featureWithError === undefined) {
            createToastMessage(
              <ToastMessage
                messageType='error'
                title={`Error saving actions: `}
                message={typeof errors === 'string' ? errors : Object.values(errors).join(', ')}
              />,
            )
          } else {
            const featureTitle = config ? buildTitleForFeature(config, featureWithError) : 'Unknown'

            // For each field in the feature, display the errors.
            Object.entries(errors).map(([field, errorCodes]) => {
              createToastMessage(
                <ToastMessage
                  messageType='error'
                  title={`Error with action: ${featureTitle}`}
                  message={`${labels[field]} has the following errors: ${errorCodes.join(', ')}`}
                />,
              )
            })
          }
        })
      } catch (e) {
        elasticApm.captureError(e)
        createToastMessage(
          <ToastMessage messageType='error' title='Error' message={`${response.code}: Could not save features.`} />,
        )
      }
    },
  })

  return mutation
}

export function useFeatureState({ featuresUrl, featureFilters, config }: UseFeatureStateProps): UseFeatureStateReturn {
  const [features, setFeatures] = useState<FeatureCollection<Geometry> | undefined>(undefined)

  const [state, dispatch] = useReducer(featureStateReducer, {
    featureFilters: featureFilters ?? [],
    collections: undefined,
    initialised: false,
    isDirty: false,
  })
  const { isSuccess, isError, error, data: getData, refetch, isLoading: getIsLoading } = useFeaturesQuery(featuresUrl)
  const { data: updateData, mutateAsync, isLoading: updateIsLoading } = useFeaturesUpdate(featuresUrl, config)

  // Set the features to the most up to date data.
  useEffect(() => {
    setFeatures(getData?.data)
  }, [getData])

  useEffect(() => {
    setFeatures(updateData?.data)
  }, [updateData])

  const isLoading = getIsLoading || updateIsLoading

  useErrorHandler(isError, error)

  useEffect(() => {
    if (isSuccess && features) {
      dispatch({ type: 'init', collection: features })
    }
  }, [isSuccess, features])

  const upsertFeature = useCallback(
    (feature: Feature<Geometry>) => {
      dispatch({ type: 'upsert', feature })
    },
    [dispatch],
  )

  const removeFeature = useCallback(
    (featureId: string | number) => {
      dispatch({ type: 'remove', featureId })
    },
    [dispatch],
  )

  const saveFeatures = useCallback(
    async (data: FeatureCollection<Geometry>) => {
      await mutateAsync(data)
    },
    [refetch],
  )

  const addFeatureFilter = useCallback(
    (filter: FeatureFilterFunction) => {
      dispatch({ type: 'add-feature-filter', filter })
    },
    [dispatch],
  )

  const removeFeatureFilter = useCallback(
    (filter: FeatureFilterFunction) => {
      dispatch({ type: 'remove-feature-filter', filter })
    },
    [dispatch],
  )

  return {
    state,
    isLoading,
    removeFeature,
    upsertFeature,
    saveFeatures,
    addFeatureFilter,
    removeFeatureFilter,
  }
}
