import { Feature, Geometry } from '@turf/helpers'
import { FeatureState } from '../../../components/feature-map/utils/state-management'
import {
  Accumulator,
  FeatureAction,
  FencingGatesTroughsActionSummary,
  FencingGatesTroughsTotalCost,
  PlantingActionSummary,
} from '../../../components/pdf/types'
import { ApplicationDetailsFields } from '../../../routes/plans/[mode]/[id]/application/[applicationId]/details/config'
import { FeatureType } from '../../../config/map'
import { toDecimalFormat } from '../../utils'

export const getFeatureFieldValue = (data: FeatureAction, key: string): number => Number(data.properties?.[key] ?? 0)
export const toSentenceCase = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export const getPlantingActionSummary = (feature: FeatureAction) => {
  return {
    area: getFeatureFieldValue(feature, 'area'),
    numberOfPlants: getFeatureFieldValue(feature, 'numberOfPlants'),
    numberOfPlantsZoneA: getFeatureFieldValue(feature, 'numberOfPlantsZoneA'),
    numberOfPlantsZoneB: getFeatureFieldValue(feature, 'numberOfPlantsZoneB'),
    numberOfPlantsZoneC: getFeatureFieldValue(feature, 'numberOfPlantsZoneC'),
    numberOfPlantsZoneD:
      feature.properties && 'numberOfPlantsZoneD' in feature.properties
        ? getFeatureFieldValue(feature, 'numberOfPlantsZoneD')
        : 0,
  }
}

const aggregatePlantingSummaryFields = (
  existingValues: Accumulator<PlantingActionSummary>,
  plantingFeature: FeatureAction,
) => {
  const location = plantingFeature.properties?.['locationName']
  // Generate a summary for a planting feature
  const values: PlantingActionSummary = getPlantingActionSummary(plantingFeature)
  const fields = Object.keys(values)

  const aggregateFieldValue = (fields: string[], accessor: string) => {
    fields.forEach(
      (field) =>
        (existingValues[accessor][field as keyof PlantingActionSummary] +=
          values[field as keyof PlantingActionSummary]),
    )
  }

  // Aggregate location[field] values
  if (location in existingValues) {
    aggregateFieldValue(fields, location)
  } else {
    existingValues[location] = values
  }

  // Aggregate Total[field] values
  aggregateFieldValue(fields, 'Total')

  return { ...existingValues }
}

const flattenAggregates = (obj: { [key: string]: object }, flattenedKey: string) => {
  return Object.entries(obj).map(([key, value]) => ({ [flattenedKey]: key, ...value }))
}

const sortFeaturesRowsByLocationName = (data: { [x: string]: string }[], sortByKey = 'locationName') => {
  const nonSummaryRows = data.filter((action) => action.locationName.toLowerCase() !== 'total')

  return [
    ...nonSummaryRows.sort((a, b) => a[sortByKey].localeCompare(b[sortByKey])),
    ...data.filter((action) => action.locationName.toLowerCase() === 'total'),
  ]
}

export const getPlantingSummary = (data: FeatureAction[]) => {
  const platingAggregates = data.reduce(aggregatePlantingSummaryFields, {
    Total: {
      area: 0,
      numberOfPlants: 0,
      numberOfPlantsZoneA: 0,
      numberOfPlantsZoneB: 0,
      numberOfPlantsZoneC: 0,
      numberOfPlantsZoneD: 0,
    },
  })

  const aggregates = flattenAggregates(platingAggregates, 'locationName')
  return sortFeaturesRowsByLocationName(aggregates)
}

const aggregateFencingGatesTroughs = (
  existingValues: Accumulator<FencingGatesTroughsActionSummary | undefined>,
  feature: FeatureAction,
): Accumulator<FencingGatesTroughsActionSummary> => {
  const featureValues = feature.properties
  const featureType = featureValues?.type ?? 'unknownFeature'
  const location = featureValues?.locationName
  const fencingName = ['Fencing', featureValues?.fenceType?.label].filter((s) => s != null).join(' - ')
  const fencingKey = [location, fencingName].join(' - ')
  const key = ['gate', 'trough'].includes(featureType) ? featureType : fencingKey
  const quantity = (existingValues[key]?.quantity ?? 0) + Number(featureValues?.unitCount ?? featureValues?.length ?? 0)
  const estimatedCost = (existingValues[key]?.estimatedCost ?? 0) + Number(featureValues?.proposedCost ?? 0)

  existingValues[key] = {
    action: ['gate', 'trough'].includes(featureType) ? featureType : fencingName,
    locationName: ['gate', 'trough'].includes(featureType) ? '' : location,
    quantity,
    estimatedCost,
  }

  return { ...existingValues } as Accumulator<FencingGatesTroughsActionSummary>
}
export interface PPMSummary {
  action: string
  quantity: string | number
  estimatedCost: number
}

export const getFencingGatesTroughsSummary = (features: FeatureAction[]): FencingGatesTroughsTotalCost[] => {
  const aggregates = features?.reduce(aggregateFencingGatesTroughs, {}) ?? {}
  const flattenedAggregates = flattenAggregates(aggregates, 'action') as unknown as FencingGatesTroughsTotalCost[]
  return flattenedAggregates.sort((a, b) => {
    if (['gate', 'trough'].includes(a.action.toLowerCase())) return 1
    if (['gate', 'trough'].includes(b.action.toLowerCase())) return -1

    return (a.locationName + a.action).localeCompare(b.locationName + b.action)
  })
}

export const getFencingGatesTroughsTotalCost = (data: FencingGatesTroughsTotalCost[]) =>
  data.reduce((previousValue: number, { estimatedCost }) => previousValue + Number(estimatedCost), 0)

export const getTotalNumberOfPlants = (data: FeatureAction[]) =>
  data.reduce((previousValue: number, { properties }) => previousValue + Number(properties?.numberOfPlants ?? 0), 0)

export const getPlantingPreparationMaintenanceSummary = (
  data: ApplicationDetailsFields | undefined,
  plantingFeatures: FeatureAction[],
): PPMSummary[] => {
  const proposedTotals = data?.proposedTotals
  const totalNumberOfPlants = getTotalNumberOfPlants(plantingFeatures)
  const totalAdditionalCosts =
    data?.additionalCosts.reduce((totalCost, additionalCost) => (totalCost += additionalCost.cost ?? 0), 0) ?? 0

  const fields = [
    {
      action: 'Site preparation',
      quantity: `${toDecimalFormat(proposedTotals?.sitePreparationArea) ?? 0}m²`,
      estimatedCost: Number(proposedTotals?.sitePreparationCost ?? 0),
    },
    {
      action: 'Plant freight',
      quantity: `${toDecimalFormat(totalNumberOfPlants)} plants`,
      estimatedCost: Number(data?.estimatedFreightCost) ?? 0,
    },
    {
      action: 'Plant costs',
      quantity: `${toDecimalFormat(totalNumberOfPlants)} plants`,
      estimatedCost: Number(proposedTotals?.plantingPlantCost ?? 0),
    },
    {
      action: 'Plant labour',
      quantity: `${toDecimalFormat(totalNumberOfPlants)} plants`,
      estimatedCost: Number(proposedTotals?.plantingLabourCost ?? 0),
    },
    {
      action: 'Maintenance',
      quantity: `${toDecimalFormat(proposedTotals?.siteMaintenanceArea) ?? 0}m²`,
      estimatedCost: Number(proposedTotals?.siteMaintenanceCost ?? 0),
    },
    {
      action: 'Project admin costs',
      quantity: '-',
      estimatedCost: totalAdditionalCosts,
    },
  ]
  return fields.filter((fields) => fields.estimatedCost > 0)
}

export const getPlantingPreparationMaintenanceCost = (data: PPMSummary[]) =>
  data.reduce((previousValue: number, { estimatedCost }) => previousValue + Number(estimatedCost), 0)

export const getFeaturesByType = (collectionState: FeatureState, type: FeatureType | FeatureType[]) =>
  collectionState.collections?.filtered?.features?.filter((feature: Feature) =>
    Array.isArray(type) ? type.includes(feature.properties?.type) : feature.properties?.type === type,
  ) ?? []

export const getNonMaintenancePreperationFeatures = (features: FeatureAction[] | undefined, projectId: string) =>
  features?.filter(
    (feature: Feature) =>
      feature.properties?.application?.value == projectId && feature.properties?.category !== 'maintenance',
  ) ?? []

export const getPreviouslyFundedFencingFeatures = (features: FeatureAction[] | undefined, projectId: string) => {
  if (!Array.isArray(features)) return []

  const getIsFundedFencingAction = (feature: Feature) => feature.properties?.type === 'fundedFencing'
  const getIsEarlierProject = (feature: Feature, projectId: string) =>
    parseInt(feature.properties?.application?.value) < parseInt(projectId)

  return features?.reduce((previouslyFundedFences: Array<Feature<Geometry>>, currentFeature: Feature<Geometry>) => {
    if (!currentFeature.properties) return previouslyFundedFences

    const isFundedFencingAction = getIsFundedFencingAction(currentFeature)
    const isEarlierProject = getIsEarlierProject(currentFeature, projectId)
    if (isEarlierProject && isFundedFencingAction) {
      // Mutates the feature type and append to list
      currentFeature.properties['type'] = 'previouslyFundedFencing'
      previouslyFundedFences.push(currentFeature)
    }

    return previouslyFundedFences
  }, [])
}
