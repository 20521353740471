import { Button } from '@msaf/core-react'
import { ReactNode } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from './error-full-page'
import { elasticApm } from '../logging/elastic-apm'

const logError = (error: Error) => {
  elasticApm.captureError(error)
}

const ErrorBlock = () => {
  return (
    <ErrorPage header='Something went wrong'>
      <p>If the problem persists, please contact your administrator.</p>
      <Button label='Go home' path='/' />
    </ErrorPage>
  )
}

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  return (
    <ReactErrorBoundary fallbackRender={ErrorBlock} onError={logError}>
      {children}
    </ReactErrorBoundary>
  )
}
