import { Id } from '@turf/helpers'
import { useContext } from 'react'
import { FeatureMapContext } from '../context'
import { FeatureSelectionPanel } from '../feature-selection-panel'

export interface EditFeaturesPanelProps {
  setIsMenuPanelOpen: (isOpen: boolean) => void
  isSkeleton?: boolean
}

export function EditFeaturesPanel({ setIsMenuPanelOpen }: EditFeaturesPanelProps) {
  const { editFeature } = useContext(FeatureMapContext)

  return (
    <FeatureSelectionPanel
      handleClick={(featureId: Id) => editFeature?.(featureId)}
      setIsMenuPanelOpen={setIsMenuPanelOpen}
    />
  )
}
