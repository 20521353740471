import { init as initApm, AgentConfigOptions, ApmBase } from '@elastic/apm-rum'
import { ELASTIC_APM_ENVIRONMENT_LABEL, CLIENT_ADDRESS, APPLICATION_VERSION } from '../environment.ts'

class SingletonLogger {
  static instance: ApmBase
  static config = {
    serviceName: 'kmr-srp-tool',
    serverUrl: 'https://f118e1cc2e8d454a9cb1165e76783601.apm.ap-southeast-2.aws.cloud.es.io:443',
    serviceVersion: APPLICATION_VERSION,
    active: CLIENT_ADDRESS?.includes('localhost') ? false : true,
    instrument: true,
    disableInstrumentations: ['page-load', 'history', 'eventtarget', 'click', 'xmlhttprequest'],
    environment: ELASTIC_APM_ENVIRONMENT_LABEL ?? 'local',
    logLevel: 'error',
  }

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  // eslint-disable-next-line  @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance() {
    if (!this.instance) {
      this.instance = initApm(this.config as AgentConfigOptions)
    }
    return this.instance
  }
}

export const elasticApm = SingletonLogger.getInstance()
