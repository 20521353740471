import { KeycloakConfig } from 'keycloak-js'
import { AuthStrategy, createAuthentication } from './authentication'
import { KeycloakAuth, LocalStorageAuth } from './authentication/methods'
import { CLIENT_ADDRESS, KEYCLOAK, SERVER_ADDRESS } from './environment'
import { User } from './types/user'
import { elasticApm } from './logging/elastic-apm'

const KEYCLOAK_CONFIG = {
  url: KEYCLOAK.ADDRESS,
  clientId: KEYCLOAK.CLIENT_ID,
  realm: KEYCLOAK.REALM,
} as KeycloakConfig // Cast to a config, keycloak-js will error if the address has not been provided.

const getUser = async (authStrategy: AuthStrategy): Promise<User> => {
  const response = await fetch(new URL('/api/user/whoami', SERVER_ADDRESS).toString(), {
    headers: authStrategy.toHeader(),
  })

  const user = (await response.json()) as User
  if (!response.ok) {
    throw new Error('Failed')
  }

  elasticApm.setUserContext({
    id: String(user.id),
    username: `${user.firstName} ${user.lastName}`,
    email: user.email,
  })

  return user
}

export const { AuthProvider, AuthContext, useAuth, useUser } = createAuthentication<User>(
  [
    new LocalStorageAuth({ localStorageKey: 'api-key' }),
    new KeycloakAuth({
      config: KEYCLOAK_CONFIG,
      frontendUri: CLIENT_ADDRESS,
      initOptions: {
        pkceMethod: KEYCLOAK.PKCE_METHOD,
      },
    }),
  ],
  getUser,
)
