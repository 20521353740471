import { AppShell, MenuActionProps, MenuLinkProps, NavPanel, ScrollToTop } from '@msaf/core-react'
import classNames from 'classnames'
import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../auth'
import { ADMIN_NAV_PANEL_LINKS, APP_LOGO, NAV_PANEL_LINKS } from '../constants'
import { useAppContext } from '../contexts/app-context'
import { elasticApm } from '../logging/elastic-apm'
import { usePermission } from '../services/permissions'
import { UserPermissions } from '../types/permissions'
import { Gatekeeper } from './gatekeeper'

export default function Page() {
  const [isMenuHidden, setIsMenuHidden] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)

  const { logout, user } = useAuth()
  const isAdminUser = usePermission(UserPermissions.ADMIN)
  const { pageActions } = useAppContext()

  //TODO: Remove fields once https://github.com/mediasuitenz/msaf-client/pull/55 is merged.
  const appUser = user
    ? {
        initials: `${user.firstName[0]}${user.lastName[0]}`,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        hasValidToken: true,
      }
    : undefined

  const userMenuLinks: Array<MenuActionProps | MenuLinkProps> = [
    {
      path: '',
      label: 'Logout',
      icon: 'exit',
      action: () => {
        logout()
          .then(() => {
            // Clear user context after successfully logging out.
            elasticApm.setUserContext({})
          })
          .catch()
      },
    },
  ]

  const mainMenuLinks = NAV_PANEL_LINKS.concat(isAdminUser ? ADMIN_NAV_PANEL_LINKS : [])

  const actionsMenuItems = pageActions?.map((action) => ({
    label: action.label,
    action: () => {
      action.action()
      setIsActionMenuOpen(false)
    },
  }))

  return (
    // Note, we need the l-page--menu-open class in order for the NavPanel component to work correctly.
    // TODO: Extract shell of `Page` into a `BasePage` msaf component that does nothing other than forward
    // on the NavPanel (and maybe AppShell) props.
    <div className={classNames('l-page', { 'l-page--menu-open': isMenuOpen })}>
      {/* If there is a record container present, scroll to the top on nav. */}
      <ScrollToTop targetDiv='.l-record-container__content' behavior='smooth' />

      <NavPanel
        appLogo={APP_LOGO}
        mainMenuLinks={mainMenuLinks}
        isMenuHidden={isMenuHidden}
        closeMenu={() => setIsMenuOpen(false)}
      />
      <AppShell
        hideMenu={(isHidden: boolean) => setIsMenuHidden(isHidden)}
        openMenu={() => setIsMenuOpen(true)}
        userMenuLinks={userMenuLinks}
        unauthenticatedUserComponent={<></>}
        isNotificationsMenu={false}
        isDownloadsMenu={false}
        isSearchMenu={false}
        user={appUser}
        actionsMenuOptions={{
          isOpen: isActionMenuOpen,
          setIsOpen: setIsActionMenuOpen,
        }}
        actionsMenuItems={actionsMenuItems}
      >
        <Gatekeeper inPage>
          <Outlet />
        </Gatekeeper>
      </AppShell>
    </div>
  )
}
